import React, {useContext} from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { ThemeContext } from 'providers/ThemeProvider';
import ToggleTheme from 'components/theme/Header/ToggleTheme';
import { Wrapper, Link } from './styles';

const NavbarLinks = ({ desktop }) => {
  const { theme } = useContext(ThemeContext);

  return (
    <Wrapper desktop={desktop} theme={theme}>
      <a href="https://www.spellslinger.app" target="_blank">Spell Slinger</a>
      <a href="https://www.github.com/jsuvajac/" target="_blank">Github</a>
      <a href="https://www.linkedin.com/in/jsuvajac/" target="_blank">LinkedIn</a>
      <AnchorLink href="#projects">Projects</AnchorLink>
      {/* <AnchorLink href="#contact">Contact</AnchorLink> */}
      <ToggleTheme />
    </Wrapper>
  )

};

export default NavbarLinks;
